body {
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  color: #000;
  font-family: "Proxima Nova", Tahoma, Helvetica, Verdana, sans-serif;
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  height: 2px;
  background: #f00;
}

.progress {
  transition: width 1s;
  position: fixed;
  top: 0;
  left: 0;
  height: 2px;
  background: lightblue;
  box-shadow: 0px 1px 5px 1px black;
  z-index: 9;
}

.bg {
  transition: opacity 4s ease-in-out;
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
	animation: Gradient 15s ease infinite;
  z-index: -1;
}

.bg .cur {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  visibility: visible;
  animation: FadeIn 1s;
  z-index: 2;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}


.main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.inner {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
  width: 620px;
}

.inner-content {
  padding: 20px;
}

.header {
  font-size: 24px;
  line-height: 48px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  margin: 0;
  color: #333;
}

.message {
  margin: 20px 90px 0;
  padding: 0;
  outline: 0;
  color: #666;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  line-height: 19.2px;
}

.btns {
  padding: 20px 0 0;
}

.btn {
  background: #305B90;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  user-select: none;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  line-height: 38px;
  transition: all .3s;
  padding: 0 20px 0;
}

.btn:hover {
  transition: all .3s;
  background: #597ca6;
}

.links {
  display: block;
  margin-top: 20px;
  align-items: center;
  line-height: 32px;
}

.link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  color: #9f9f9f;
}

.link * {
  padding: 0 4px;
}

.link:hover {
  color: #999;
  text-decoration: underline;
}

.leader {
  font-size: 18px;
  margin: 12px 0 8px 0;
}

.content {
  margin: 2px 0 8px 0;
  font-size: 22px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 20px 0 12px 0;
}

.list li {
  display: inline-block;
  margin: 12px 4px 12px 4px;
}

.list a {
  background: #fff;
  padding: 8px 16px;
  font-weight: bold;
  color: #333;
  text-shadow: none;
  border: 1px solid #fff;
  border-radius: 6px;
  text-decoration: none;
}

.list a:hover {
  color: rgb(51, 51, 51);
  background: rgb(230, 230, 230);
  border-color: rgb(173, 173, 173);
}

.contact {
  margin: 10px 20px;
  color: #9f9f9f;
}

.contact p {
  font-size: 13px;
  margin: 0;
}

.footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  text-shadow: 0px 0px 2px black;
}

.footer a {
  color: #fff;
}

@media screen and (max-width: 980px) {
  .inner {
    width: 95%;
  }

  .inner-content {
    padding: 5px;
  }

  .message {
    margin: 20px 10px 0;
  }
}

@keyframes indicator-1 {
  0% {
    left: -35%;
    right: 100%;
  }
  50% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indicator-2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.progress-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  background: #1976d2;
  transition: all 0.1s linear;
  transform-origin: left;
}

.anim-1 {
  animation: indicator-1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.anim-2 {
  animation: indicator-2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
}

.progress-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgb(167, 202, 237);
}
