body {
  text-align: center;
  height: 100%;
  width: 100%;
  color: #000;
  margin: 0;
  font-family: Proxima Nova, Tahoma, Helvetica, Verdana, sans-serif;
  position: fixed;
}

aside {
  height: 2px;
  background: red;
  position: fixed;
  top: 0;
  left: 0;
}

.progress {
  height: 2px;
  z-index: 9;
  background: #add8e6;
  transition: width 1s;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 1px 5px 1px #000;
}

.bg {
  height: 100%;
  opacity: 1;
  width: 100%;
  z-index: -1;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 / 400% 400%;
  transition: opacity 4s ease-in-out;
  animation: 15s infinite Gradient;
  position: fixed;
  top: 0;
  left: 0;
}

.bg .cur {
  height: 100%;
  width: 100%;
  visibility: visible;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: cover;
  animation: 1s FadeIn;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.main {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.inner {
  width: 620px;
  background: #fffc;
  border-radius: 4px;
}

.inner-content {
  padding: 20px;
}

.header {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #333;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
}

.message {
  color: #666;
  outline: 0;
  margin: 20px 90px 0;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  line-height: 19.2px;
}

.btns {
  padding: 20px 0 0;
}

.btn {
  color: #fff;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: baseline;
  background: #305b90;
  border-radius: 5px;
  outline: 0;
  margin: 0;
  padding: 0 20px;
  font-weight: bold;
  line-height: 38px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn:hover {
  background: #597ca6;
  transition: all .3s;
}

.links {
  align-items: center;
  margin-top: 20px;
  line-height: 32px;
  display: block;
}

.link {
  cursor: pointer;
  color: #9f9f9f;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
}

.link * {
  padding: 0 4px;
}

.link:hover {
  color: #999;
  text-decoration: underline;
}

.leader {
  margin: 12px 0 8px;
  font-size: 18px;
}

.content {
  margin: 2px 0 8px;
  font-size: 22px;
}

.list {
  margin: 20px 0 12px;
  padding: 0;
  list-style: none;
}

.list li {
  margin: 12px 4px;
  display: inline-block;
}

.list a {
  color: #333;
  text-shadow: none;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: bold;
  text-decoration: none;
}

.list a:hover {
  color: #333;
  background: #e6e6e6;
  border-color: #adadad;
}

.contact {
  color: #9f9f9f;
  margin: 10px 20px;
}

.contact p {
  margin: 0;
  font-size: 13px;
}

.footer {
  text-align: center;
  color: #fff;
  text-shadow: 0 0 2px #000;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.footer a {
  color: #fff;
}

@media screen and (max-width: 980px) {
  .inner {
    width: 95%;
  }

  .inner-content {
    padding: 5px;
  }

  .message {
    margin: 20px 10px 0;
  }
}

@keyframes indicator-1 {
  0% {
    left: -35%;
    right: 100%;
  }

  50% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indicator-2 {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.progress-indicator {
  transform-origin: 0;
  background: #1976d2;
  transition: all .1s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.anim-1 {
  animation: 2.1s cubic-bezier(.65, .815, .735, .395) infinite indicator-1;
}

.anim-2 {
  animation: 2.1s cubic-bezier(.165, .84, .44, 1) 1.15s infinite indicator-2;
}

.progress-indicator-container {
  height: 4px;
  background: #a7caed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=index.87a8d4bc.css.map */
